


































































































































































































import { defineComponent, ref, computed } from '@vue/composition-api'

export default defineComponent({
  name: 'v-meter',
  components: {},
  props: {
    integers: {
      type: Number,
      default: 0,
    },
    decimals: {
      type: Number,
      default: 0,
    },
    value: {
      type: String,
      default: '',
    },
    minlength: Number,
    maxlength: Number,
    required: Boolean,
    integerOnly: {
      type: Boolean,
      default: true,
    },
    parentDivStyle: {
      type: Object,
      default: () => ({}),
    },
    inputStyle: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, ctx) {
    const input = computed({
      get: () => {
        const val = props.value.replace('.', '')

        return val
      },
      set: val => {
        let value = val

        if (props.integerOnly) {
          value = val.replace(/\D+/g, '')
        }

        if (val.length > props.integers) {
          const valuesArray = value.split('')
          valuesArray.splice(props.integers, 0, '.')
          const updatedValue = valuesArray.join('')

          value = updatedValue
        }

        ctx.emit('input', value)

        return value
      },
    })

    const inputEL = ref<HTMLInputElement | null>()

    const resetInput = () => {
      setTimeout(() => {
        // Input.value = ''
        inputEL.value!.value = ''

        ctx.emit('input', '')
      }, 100)
    }

    const onInput = e => {
      let { value } = e.target

      if (
        value.length > props.integers + props.decimals ||
        isNaN(e.target.value)
      ) {
        value = value.substring(0, value.length - 1)
        inputEL.value!.value = value
      }
    }

    const onPaste = e => {
      inputEL.value!.value = ''
      ctx.emit('input', '')

      let pastedData
      let value

      if (e.clipboardData && e.clipboardData.getData) {
        pastedData =
          e.clipboardData.getData('text/plain') ||
          e.clipboardData.getData('Text')

        if (
          pastedData.split('').some(item => isNaN(item)) &&
          props.integerOnly
        ) {
          resetInput()
        } else {
          value = pastedData.substring(0, props.integers)
          setTimeout(() => {
            inputEL.value!.value = value
          }, 100)
        }
      } else {
        resetInput()
      }

      return true
    }

    return {
      input,
      inputEL,
      onInput,
      onPaste,
    }
  },
})
