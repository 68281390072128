var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-ppp wrapper m-l"},[(_vm.i18n && _vm.myI18n.prepayment)?_c('div',{staticClass:"row"},[_c('form',{staticClass:"col-xxxs col-l-14",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[(_vm.myI18n.prepayment.title.length > 0)?_c('h1',{staticClass:"mb-m"},[_vm._v(" "+_vm._s(_vm.myI18n.prepayment.title)+" ")]):_vm._e(),(_vm.myI18n.prepayment.optionHtml.displayContent.length > 0)?_c('div',{staticClass:"mt-xxs mb-s",domProps:{"innerHTML":_vm._s(_vm.myI18n.prepayment.optionHtml.htmltext)}}):_vm._e(),(_vm.myI18n.prepayment.subtitle.length > 0)?_c('h2',{staticClass:"h3 fatline mb-s"},[_vm._v(" "+_vm._s(_vm.myI18n.prepayment.subtitle)+" ")]):_vm._e(),(_vm.showSingIn)?_c('message',{staticClass:"mb-s pt-m pb-m",attrs:{"content":{
          htmltext: _vm.error,
        },"severity":"warning"}},[_c('g-action',{key:"out",staticClass:"mt-s",attrs:{"content":{
            tag: 'button',
            label: _vm.myI18n.login.button.label,
            icon: 'arrow',
          }},on:{"on-click":_vm.signIn}})],1):_c('div',{staticClass:"token mb-s pt-m pb-m"},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.i18n.token.fields.code)),_c('sup',[_vm._v("*")])]),_c('v-meter',{staticClass:"meter--light--big",attrs:{"integer-only":false,"integers":_vm.tokenLength,"maxlength":_vm.tokenLength,"minlength":_vm.tokenLength,"required":true},model:{value:(_vm.token),callback:function ($$v) {_vm.token=$$v},expression:"token"}}),(_vm.globalI18n)?_c('span',{staticClass:"small col-xxxs-20"},[_vm._v(" "+_vm._s(_vm.globalI18n.requiredFields)+" ")]):_vm._e(),(_vm.error)?_c('feedback-message',{staticClass:"mt-s",attrs:{"content":{ type: 'error', htmltext: _vm.error }}}):_vm._e()],1),(!_vm.showSingIn)?_c('g-action',{staticClass:"submit",class:{ 'is-loading': _vm.isLoading },attrs:{"content":{
          tag: 'button',
          label: _vm.i18n.token.button.label,
          icon: 'arrow',
        },"disabled":_vm.token.length < _vm.tokenLength,"type":"submit"}}):_vm._e()],1),(_vm.i18n.token)?_c('aside',{staticClass:"aside col-xs-20 col-l-5 col-l-offset-1 col-xxl-4 col-xxl-offset-2"},[_c('tip',{staticClass:"mb-s",attrs:{"content":_vm.i18n.token.tip}})],1):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }