



















































































import auth from '@/composables/auth'
import axios, { AxiosResponse } from 'axios'
import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  ref,
  watch,
} from '@vue/composition-api'
import { getApiUrl } from '@/inc/app.config'
import { fetchLive } from '@/inc/utils'
import * as sso from '@/composables/sso'
import { useState } from '@u3u/vue-hooks'

import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'
import Message from '@/components/ui/Message.vue'
import Tip from '@/components/Tip.vue'
import VMeter from '@/components/VMeter.vue'

export default defineComponent({
  name: 'my-ppp',
  components: {
    FeedbackMessage,
    Message,
    Tip,
    VMeter,
  },
  setup(_props, ctx) {
    const { $logger, $router, $route } = ctx.root
    const { chrome } = useState(['chrome'])

    $logger.trace('Ppp', auth.currentUser.value, auth.isGhost.value)

    /* eslint-disable @typescript-eslint/no-explicit-any */
    const content = reactive<Record<string, any>>({ error: '' })
    const i18n = ref<Record<string, any>>()
    /* eslint-enable @typescript-eslint/no-explicit-any */

    const isLoading = ref(false)
    const error = ref('')
    const showSingIn = ref(false)
    const token = ref('')
    const tokenLength = 10

    onBeforeMount(async () => {
      const { route: createRoute } = $router.resolve('/create')
      const res: AxiosResponse = await fetchLive(createRoute)

      token.value = ($route.query.token as string) ?? ''
      i18n.value = res.data.i18n || {}

      if (token.value) {
        onSubmit()
      }
    })

    const signIn = () => {
      sso.authorize('/dashboard/')
    }

    const goToNextStep = data => {
      const routeName = data.emailExist ? 'MyPppChoice' : 'MyPppSignup'

      $router
        .push({
          name: routeName,
          params: { user: data, token: token.value },
        })
        .catch($logger.trace)
    }

    const onSubmit = async () => {
      showSingIn.value = false
      isLoading.value = true
      error.value = ''

      if (!auth.isGhost.value && !auth.isLogged.value) {
        const oops = 'No user 👨‍💼, no ghost 👻 … no way'
        $logger.error(oops)
        content.error = oops
      }

      try {
        const headers: Record<string, string> = {}

        if (auth.sessionId === null || auth.currentUser.value === null) {
          let ghost = await auth.currentGhostAsync

          if (ghost === null) {
            ghost = await auth.createGhost()
          }

          if (ghost?.sessionId) {
            headers.SessionId = ghost.sessionId
          }
        }
        const res = await axios.post(`${getApiUrl()}/token`, null, {
          headers,
          params: { Token: token.value },
        })

        $logger.trace('RES', res.data)

        if (res.status !== 200) {
          error.value = res.data?.message
          isLoading.value = false

          return
        }

        if (auth.currentUser.value) {
          $router
            .push({
              name: 'MyPppSuccess',
              params: { user: res.data },
            })
            .catch($logger.trace)
        } else {
          goToNextStep(res.data)
        }
      } catch (err) {
        if (err.response.status === 503) {
          showSingIn.value = true
        }

        error.value = err.response?.data?.message
        $logger.error(error)
      }

      isLoading.value = false
    }

    watch(token, () => {
      error.value = ''
    })

    const globalI18n = computed(() => chrome.value.data.i18n)

    const myState = {
      ...useState('my', ['i18n']),
    }

    return {
      globalI18n,
      content,
      ghost: auth.isGhost,
      i18n,
      isLoading,
      error,
      onSubmit,
      signIn,
      showSingIn,
      token,
      tokenLength,
      user: auth.currentUser,
      myI18n: myState.i18n,
    }
  },
})
